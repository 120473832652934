@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins/breakpoints';

.badge {
  color: $nikon-blood-orange;
  text-transform: uppercase;
  text-decoration: none;
  cursor: default;
  margin-right: 5px;
}

.promotion {
  color: $nikon-blood-orange;
  font-size: rfs-value(12px);
}

.button {
  display: block;
  text-align: center;
  width: 100%;
  background-color: $nikon-yellow;
  border: #cb0;
  color: $nikon-dark-grey;

  &:hover {
    background-color: #f0d000;
  }

  &:active {
    background-color: $nikon-yellow;
  }
}

.listItem {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  column-gap: 30px;

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr 2fr;
    column-gap: 15px;
    border-top: 1px solid #dedede;
  }
}

.variant-swatches {
  width: 100%;
  height: 20px;
  display: flex;
  gap: 0.3rem;

  @include media-breakpoint-down(md) {
    display: none;
  }

  & img {
    height: 100%;
  }

  & > div {
    padding: 1px;

    &.selected {
      border: solid 1px $nikon-grey-border;
    }

    &:hover {
      border: solid 1px $nikon-yellow-hover;
    }
  }
}

.tileBody {
  padding: 30px 15px;
}

.currentPrice {
  font-weight: bold;
  font-size: rfs-value(14px);
  margin-bottom: 0;

  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.price-was {
  font-size: rfs-value(14px);
  line-height: rfs-value(23.8px);
  text-decoration: line-through;
}
.you-save {
  margin-bottom: rfs-value(12px);
  font-size: rfs-value(12px);
  font-weight: $font-weight-bold;
  line-height: rfs-value(20px);
  color: $nikon-blood-orange;
}

@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.table-definitions {
  color: $body-color;
  font-size: rfs-value(12px);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: $font-weight-medium;
  }

  h1 {
    font-size: rfs-value(30px);
  }
  h2 {
    font-size: rfs-value(24px);
  }
  h3 {
    font-size: rfs-value(18px);
  }
  h4 {
    font-size: rfs-value(16px);
  }
  h5 {
    font-size: rfs-value(14px);
  }
  h6 {
    font-size: rfs-value(12px);
  }

  :global(.ql-size-small) {
    font-size: rfs-value(10px);
  }
  :global(.ql-size-large) {
    font-size: rfs-value(16px);
  }
  :global(.ql-size-large) {
    font-size: rfs-value(18px);
  }
  :global(.ql-size-huge) {
    font-size: rfs-value(30px);
  }

  :global(.ql-align-right) {
    text-align: right;
  }
  :global(.ql-align-center) {
    text-align: center;
  }
  :global(.ql-align-justify) {
    text-align: justify;
  }

  ol,
  ul {
    list-style: auto;
  }

  &-title {
    &-container {
      margin-bottom: rfs-value(25px);
      @include media-breakpoint-up(md) {
        margin-bottom: rfs-value(40px);
      }
    }

    font-size: rfs-value(30px);
  }

  &-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    &-item {
      margin-bottom: rfs-value(15px);
      counter-increment: table-def-counter;

      &:before {
        content: counter(table-def-counter) ' ';
        vertical-align: super;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: rfs-value(42px);
      }

      h3 {
        display: inline-block;
        margin-bottom: rfs-value(6px);
      }

      p {
        margin: 0;
      }
    }
  }
}

@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.container {
  display: grid;
  grid-template-rows: auto auto;
  gap: 1rem;
  padding-bottom: 0;
  margin-bottom: 2rem;

  &:hover {
    cursor: pointer;

    hr {
      border-color: $nikon-yellow-hover;
      border-width: 3px;
    }

    .title {
      text-decoration: underline;
    }
  }
}

.separator {
  height: 3px;

  hr {
    margin: 0;
  }
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div:first-child {
    height: 100%;
    display: flex;
    justify-content: center;

    & > img {
      max-height: 200px;
      height: 100%;
      transition: all 0.2s ease-in-out;
    }
  }
}

.title {
  font-size: rfs-value(18px);
}

@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.container {
  display: grid;
  grid-template-rows: auto auto;
  gap: 1rem;
  padding: 2rem;
  padding-bottom: 0;
  margin-bottom: 2rem;

  a {
    text-decoration: none;
    color: $nikon-black;
  }

  &:hover {
    cursor: pointer;

    .image-container > div:first-child > img {
      transform: translateY(-20px);
      filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 25px 17px);
    }

    hr {
      border-color: $nikon-yellow-hover;
      border-width: 3px;
    }
  }
}

.separator {
  height: 3px;
  margin: rfs-value(20px 0 15px);

  hr {
    margin: 0;
  }
}

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div:first-child {
    height: 100%;
    display: flex;
    justify-content: center;

    & > img {
      max-height: 200px;
      height: 100%;
      transition: all 0.2s ease-in-out;
    }
  }
}

.title {
  .newOrSale {
    text-transform: uppercase;
    color: $nikon-blood-orange;
    font-weight: bold;
    margin-right: rfs-value(8px);
  }

  .product-name {
    display: inline;
  }
}

.variant-swatches {
  width: 100%;
  min-height: 20px;
  display: flex;
  gap: 0.3rem;
  flex-wrap: wrap;

  @include media-breakpoint-down(md) {
    display: none;
  }

  & img {
    height: 100%;
  }

  & > div {
    padding: 1px;
    width: 20px;
    height: 20px;

    &.selected {
      border: solid 1px $nikon-grey-border;
    }

    &:hover {
      border: solid 1px $nikon-yellow-hover;
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.price {
  font-weight: bold;
}

.rating {
  text-decoration: none;
}

.promo-message {
  color: $nikon-blood-orange;
}

.price-was {
  font-size: rfs-value(14px);
  line-height: rfs-value(23.8px);
  text-decoration: line-through;
}
.you-save {
  margin-bottom: rfs-value(12px);
  font-size: rfs-value(12px);
  font-weight: $font-weight-bold;
  line-height: rfs-value(20px);
  color: $nikon-blood-orange;
}

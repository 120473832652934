@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins/breakpoints';

.container {
  border-bottom: 1px solid $nikon-light-grey;
  font-size: rfs-value(12px);

  & > button {
    width: 100%;
    display: grid;
    align-items: center;

    & > div {
      padding: 1rem 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
}

.name {
  display: block !important;

  .newOrSale {
    text-transform: uppercase;
    color: $nikon-blood-orange;
    font-weight: bold;
    margin-right: 0.5rem;
  }
}

.product-name {
  font-size: rfs-value(14px);
}

.sku {
  font-size: rfs-value(11px);
  font-weight: bold;
}

.description {
  padding-top: 0.8rem;

  @include media-breakpoint-down(md) {
    display: none;
  }
}

.quick-view {
  display: flex;
  flex-direction: column;
}

.quick-view-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 1rem 0rem 1rem;
}

.quick-view-content {
  display: grid;
  grid-template-columns: 200px 1fr;
  gap: 2rem;
  padding-bottom: 2rem;

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr 1fr;
  }

  & > div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  & > div:last-child {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2rem;

    @include media-breakpoint-down(xxl) {
      grid-template-columns: none;
      grid-template-rows: 1fr auto;
    }

    & > div:first-child {
      display: flex;
      flex-direction: column;
    }

    & > div:last-child {
      display: flex;
      flex-direction: column;
      padding-right: 3rem;
      gap: 1rem;

      @include media-breakpoint-down(md) {
        align-items: start;
      }
    }
  }

  & img {
    height: 100%;
    max-height: 200px;
  }
}

.opened {
  background-color: $nikon-light-grey;
}

.rating {
  text-decoration: none;
}

.price-container {
    .price {
      font-size: rfs-value(14px);
    }

    .price-was {
      font-size: rfs-value(16px);
      line-height: rfs-value(25px);
      text-decoration: line-through;
    }

    .you-save {
      margin-bottom: rfs-value(12px);
      font-size: rfs-value(14px);
      font-weight: $font-weight-bold;
      line-height: rfs-value(25px);
      color: $nikon-blood-orange;
    }
}

.promo-message {
  font-size: rfs-value(14px);
  color: $nikon-blood-orange;
}

.variant-swatches {
  height: 20px;
  display: flex;
  gap: 0.3rem;

  @include media-breakpoint-down(md) {
    display: none;
  }

  & img {
    height: 100%;
  }

  & > div {
    padding: 1px;
    border: solid 1px transparent;
    cursor: pointer;

    &.selected {
      border: solid 1px $nikon-grey-border;
    }

    &:hover {
      border: solid 1px $nikon-yellow-hover;
    }
  }
}

.learn-more {
  font-size: rfs-value(12px);
  width: fit-content;
}

@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.search-results-loader-container {
  display: flex;
  margin: 30px auto;
  justify-content: center;
}

.searchMenu {
  margin-top: 18px;
  padding: 0 15px;
  justify-content: center;
  .facet {
    margin: auto;
    font-size: rfs-value(12px);
    line-height: 1.7;
    text-align: center;
    cursor: pointer;

    button:focus {
      outline: -webkit-focus-ring-color auto 1px;
    }

    &::after {
      content: ' ';
      display: inline;
      position: relative;
    }

    &:not(:first-child) {
      &::before {
        color: $nikon-search-result-border;
        content: '|';
        display: inline-block;
        padding: 0 15px;
        position: relative;
      }
    }
  }
  .selected {
    font-weight: 600 !important;
  }

  @include media-breakpoint-down(md) {
    border-radius: 2px;
    box-shadow: inset 0 0 2px 0 #70706d;
    font-size: 1rem;
    font-weight: 700;
    height: 40px;
    line-height: 28px;
    margin: 18px auto 0;
    padding: 6px 12px;
    position: relative;
    text-align: left;
    width: 260px;

    .fieldset {
      background-color: $nikon-bg-color;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      left: 0;
      padding: 20px 20px 18px;
      position: absolute;
      top: 48px;
      width: 100%;
      display: none;

      &.show {
        display: block;
      }
      .facet.selected {
        display: block;
      }
    }

    .facet {
      color: $nikon-dark-grey;
      display: block;
      font-weight: 400;
      text-decoration: none;
      text-align: left;
      margin-left: 0;

      &:not(:first-child) {
        &::before {
          display: none;
        }
      }
    }
    .fieldset .facet.selected {
      font-size: 1rem;
      left: 20px;
      line-height: 1rem;
      pointer-events: none;
      position: absolute;
      top: -35px;
      display: block !important;
      left: 0;
    }
    .facet:not(.selected) {
      font-size: 1rem;
      line-height: 1rem;
    }

    .facet:not(.selected):not(:last-of-type) {
      margin-bottom: 20px;
    }
    .facets:not(:focus) {
      overflow: hidden;
    }
    &::after {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid;
      color: $nikon-dark-grey;
      content: ' ';
      display: inline-block;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.7;
      position: absolute;
      right: 12px;
      top: 16px;
    }
  }
}
.loader {
  width: rfs-value(11px);
  display: inline;
}

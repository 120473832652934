@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.container {
  display: grid;
  background-color: $nikon-light-grey;

  & > div {
    cursor: pointer;
    user-select: none;
    padding: rfs-value(30px 10px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: rfs-value(12px);
    flex-direction: column;

    @include media-breakpoint-down(lg) {
      padding: rfs-value(20px 10px);
    }
  }
}

.selected {
  color: #fff;
  background-color: $nikon-grey;
  font-weight: bold;
  position: relative;
}

.sort-icon-selected {
  position: absolute;
  bottom: 0;
  margin: 0;
  font-size: rfs-value(12px);
  line-height: 20.4px;

  &.down::after,
  &.up::after {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: 'liga';
    font-family: SSStandard;
    font-style: normal;
    font-weight: 400;
  }

  &.down::after {
    content: '▾';
  }

  &.up::after {
    content: '▴';
  }
}

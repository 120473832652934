@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.nkn-search-page-results {
  color: $body-color;
  font-family: $font-family-generic;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;

  @include media-breakpoint-up(md) {
    max-width: 750px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 970px;
  }

  em {
    font-style: normal;
    font-weight: 700;
  }

  .searchTermResults {
    border-bottom: 1px solid $nikon-search-result-border;
    font-size: rfs-value(18px);
    font-weight: 400;
    line-height: rfs-value(20px);
    margin-top: 44px;
    padding: 0 15px 12px;

    @include media-breakpoint-up(lg) {
      font-size: rfs-value(23px);
    }
  }

  .search-result-hero {
    margin: 0 15px;
    padding: 30px 0;
    position: relative;
    text-align: left;

    a {
      text-decoration: none;
    }

    &:not(:first-child) {
      border-top: 1px solid $nikon-search-result-border;
    }

    .image {
      display: inline-block;
      margin: 0;
      position: relative;
      vertical-align: top;

      @include media-breakpoint-up(md) {
        width: 15%;
      }
      @include media-breakpoint-down(md) {
        width: 33.333%;
      }
    }

    .content {
      display: inline-block;
      vertical-align: top;

      .url {
        display: block;
      }
      .description {
        display: none;
      }

      .review {
        color: $nikon-grey;
        display: block;
        margin: 20px 0;
        position: relative;
        text-decoration: none;
      }

      @include media-breakpoint-up(md) {
        width: 85%;
        .main-text {
          display: inline-block;
          padding-right: 35px;
          width: 75%;
        }

        .url {
          margin-bottom: 15px;
          display: block;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        .description {
          display: block;
          font-family: inherit;
          font-size: rfs-value(13px);
          line-height: 20px;
        }

        .extra-content {
          display: flex !important;
          position: relative;
          vertical-align: top !important;
          width: 25%;

          a {
            text-decoration: none;
          }
        }
      }
      @include media-breakpoint-down(md) {
        width: 66.666%;
      }
    }
  }

  .search-category-hero {
    padding: rfs-value(35px 0 45px);

    @include media-breakpoint-up(md) {
      padding: rfs-value(25px 0 20px);
    }
    @include media-breakpoint-up(lg) {
      padding: rfs-value(35px 0 50px);
    }
  }
}

.searchPaginationContainer {
  margin: 50px auto;
}

.search-results-loader-container {
  margin: 2px 10px 10px;
}

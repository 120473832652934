@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: rfs-value(25px);
  border-top: 1px solid $nikon-light-grey-border;
  padding: rfs-value(25px 0);
}

.results {
  display: flex;
  font-size: rfs-value(18px);

  @include media-breakpoint-down(lg) {
    width: 100%;
    justify-content: flex-end;
    background-color: $nikon-grey;
    color: #fff;
    padding: 1rem;
  }
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 2rem;

  @include media-breakpoint-down(lg) {
    width: 100%;
    justify-content: flex-end;
  }
}

.title {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  span {
    white-space: nowrap;
    font-size: rfs-value(14px);
  }
}

.dropdown {
  padding: 0.375rem 0.75rem;
  padding-right: 2rem;
}

.dropdown-container {
  width: 100%;
  margin-bottom: 0 !important;
}

@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';

.search-field {
  width: 270px;
  margin: 0 auto;
  border: 0;
  display: inline-block;
  padding: 0;
  position: relative;
  vertical-align: top;

  input {
    border-radius: 1px;
    font-size: rfs-value(12px);
    height: 40px;
    line-height: 28px;
    width: 100%;
    padding: 6px calc(12px + 4.32rem) 6px 12px;
    box-shadow: inset 0 0 2px 0 $nikon-grey;
    border: 0;
    &:focus {
      box-shadow: inset 0 0 2px 0 $nikon-grey !important;
      outline: 0 !important;
    }
  }

  .clear-search {
    height: 38px;
    right: 38px;
    width: 38px;
    display: inline-block;
    background: 0 0;
    font-size: 0.8rem;
    line-height: 1rem;
    padding-top: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    border: none;
    position: absolute;
    top: 1px;
  }

  .submit-search {
    height: 38px;
    width: 38px;
    background: #f6f6f6;
    border-left: 1px solid $nikon-light-grey;
    right: 1px;
    position: absolute;
    top: 1px;
    border: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;

    img {
      vertical-align: middle;
      margin: auto;
    }
    &.active {
      background: #fd0;
    }
  }
}

.search-results {
  &-container {
    background-color: $nikon-bg-color;
    border: 1px solid $nikon-search-result-border;
    margin: 0;
    min-height: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 40px;
    width: 100%;
    z-index: 1001;
  }

  &-title {
    border: none;
    font-family: $font-family-generic;
    font-size: rfs-value(13px);
    font-weight: bold;
    margin: 0;
    padding: 10px;
    text-align: left;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px solid $nikon-search-result-border;

    &-item {
      border-top: 1px solid $nikon-search-result-border;
      cursor: pointer;

      &:hover,
      &:focus,
      &:focus-within,
      &.selected {
        background-color: $nikon-search-result-item-hover-bg;
      }

      &-title {
        margin: 0;
        padding: 0.5rem 1rem;
        font-size: rfs-value(13px);
        font-weight: normal;
        line-height: 1.3;
        letter-spacing: -0.11px;
      }
    }
  }

  &-loader-container {
    display: flex;
    justify-content: center;
    padding: rfs-value(20px 10px);
    border-top: 1px solid $nikon-search-result-border;
  }
}

@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins/breakpoints';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: $nikon-grey;
  color: #fff;
  padding: 2rem 1rem;
  font-size: rfs-value(18px);
  font-weight: bold;
  margin-bottom: 0;

  @include media-breakpoint-down(md) {
    background-color: transparent;
    color: $nikon-black;
    padding: rfs-value(10px 0);
  }
}

.content {
  background-color: $nikon-light-grey;
  font-size: rfs-value(12px);
  padding: rfs-value(20px 16px);
  min-height: rfs-value(120px);
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-down(md) {
    background-color: transparent;
    padding: rfs-value(0 0 20px);
    min-height: rfs-value(auto);
  }
}

.current-refinements-list {
  display: flex;
  gap: rfs-value(15px);
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 1.25rem;

  @include media-breakpoint-down(md) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.current-refinement-item {
  display: flex;
  align-items: center;
  gap: rfs-value(5px);
  cursor: pointer;
  align-self: flex-start;
  color: $nikon-dark-grey;

  &-text {
    display: inline-block;
    font-size: 0.8rem;
    line-height: 0.75rem;
  }

  &-icon {
    width: rfs-value(10px);
    min-width: rfs-value(10px);
    height: rfs-value(16px);
    fill: $nikon-grey;
  }

  &:hover &-text {
    text-decoration: underline;
    text-underline-offset: 1px;
  }
}

.clear-all-refinements-text {
  display: inline-block;
  font-size: 0.8rem;
  line-height: 0.75rem;
  color: $nikon-link-bold;
  cursor: pointer;
  align-self: flex-start;
  text-decoration: underline;
  text-underline-offset: 1px;
  padding-bottom: rfs-value(3px);

  &:hover {
    text-decoration: none;
  }
}

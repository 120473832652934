@import 'styles/variables';

.container {
  display: flex;

  button {
    padding: 0.4rem;
    background-color: #fff;
    border-top: var(--bs-border-width) solid #000;
    border-bottom: var(--bs-border-width) solid #000;

    &:hover:not(.selected) {
      & > div {
        background-color: $nikon-yellow-hover;
      }
    }

    &:first-child {
      border: var(--bs-border-width) solid #000;
      border-right: 0;
      border-top-left-radius: 0.3rem;
      border-bottom-left-radius: 0.3rem;
    }

    &:nth-child(2) {
      border: var(--bs-border-width) solid #000;
      border-right: 0;
    }

    &:last-child {
      border: var(--bs-border-width) solid #000;
      border-top-right-radius: 0.3rem;
      border-bottom-right-radius: 0.3rem;
    }
  }

  .selected {
    background-color: #e6e6e6;
    cursor: default;
    & > div {
      background-color: #666;
    }
  }
}

.icon {
  width: 24px;
  height: 24px;
  background-color: #666;
}

.grid-icon {
  @extend .icon;
  -webkit-mask: url(/icons/tailwind-icons/icon-grid.svg) no-repeat center;
  mask: url(/icons/tailwind-icons/icon-grid.svg) no-repeat center;
}

.list-icon {
  @extend .icon;
  -webkit-mask: url(/icons/icon-list.svg) no-repeat center;
  mask: url(/icons/icon-list.svg) no-repeat center;
}

// TODO: JOSH (this is from 796)
.table-icon {
  width: 22px;
  height: 22px;
  background-color: rgb(155, 155, 155);
  -webkit-mask: url(/icons/icon-columns.svg) no-repeat center;
  mask: url(/icons/icon-columns.svg) no-repeat center;
}

@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.searchSuggestionsContainer {
  margin: 0 auto;
  max-width: 100%;
  padding: 0;

  @include media-breakpoint-up(md) {
    max-width: 750px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 970px;
  }
}
.mainContainer {
  margin: 44px 0 20px;
  padding: 0 15px;
  h2 {
    color: #454545;
    font-family: $font-family-base;
    font-size: rfs-value(23px);
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
  a {
    color: $nikon-link-bold;
    font-size: rfs-value(16px);
    text-decoration: none;
    margin-top: 8px;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }
}

.searchSuggestions {
  margin-top: 20px;
  padding: 0 15px;
  color: $body-color;
  font-family: $font-family-base;
  font-size: rfs-value(12px);
  line-height: 1.7;
  .searchTerm {
    font-weight: 700;
  }
  ul {
    list-style-type: disc;
    padding-left: 40px;
  }
}

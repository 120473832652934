.searchNav {
  width: 100%;
  z-index: 99;
  padding: 0;
}
.searchBrowBar {
  width: 100%;
  display: block !important;
  margin: 0 auto;
}
.insetOverlay {
  background: radial-gradient(ellipse at right top, hsla(0, 0%, 39%, 0.2) 0, transparent 75%),
    linear-gradient(180deg, hsla(0, 0%, 39%, 0.2) 0, transparent),
    radial-gradient(ellipse at left top, hsla(0, 0%, 39%, 0.2) 0, transparent 75%);
  display: block !important;
  height: 5px;
  margin: 20px 0 0;
  padding: 0;
  width: 100%;
}

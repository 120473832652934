@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.header,
.footer {
  background-color: $nikon-grey;
  color: #fff;
  padding: 2rem 1rem;
  font-size: rfs-value(18px);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer {
  padding: 1rem;
}

.content {
  background-color: $nikon-light-grey;
  font-size: rfs-value(12px);
  padding: rfs-value(28px) 1rem;

  &-list {
    display: flex;
    flex-direction: column;
    gap: rfs-value(28px);
    padding: 0;
    margin-bottom: rfs-value(24px);

    // INFO: First 4 items are always visible
    &:global(.compress) > li:nth-child(n + 5) {
      display: none;
    }
  }

  &-button {
    font-size: rfs-value(12px);
    color: $nikon-dark-grey;
    font-weight: $font-weight-bold;

    @media screen and (min-width: map-get($grid-breakpoints, md)) {
      width: 100%;
    }

    &:hover {
      background-color: $nikon-light-grey !important;
      border-color: $nikon-filter-button-border-hover !important;
      box-shadow: none !important;
    }
  }
}

.results {
  font-size: rfs-value(12px);
}

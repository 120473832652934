@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.plp-above-grid {
  :global {
    .content-blocks {
      margin-top: rfs-value(-25px);
      margin-bottom: 0;

      & > .content-block {
        margin-top: rfs-value(25px);
        margin-bottom: rfs-value(25px);

        &.above-product-list {
          margin-top: rfs-value(45px);
          margin-bottom: rfs-value(45px);
        }
      }
    }
    .container {
      padding: rfs-value(0 15px);

      @include media-breakpoint-up(md) {
        padding: rfs-value(0 30px);
      }
    }
  }
}

.plp-grid-container {
  padding: 0;

  @include media-breakpoint-up(md) {
    padding: rfs-value(0 30px);
  }
}

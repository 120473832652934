@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.searchBrowBar {
  background: $nikon-bg-color;
  padding: 0;
  position: sticky;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 3;

  .searchField {
    margin: 0 auto;
    margin-top: 20px;
    max-width: 260px;
    border: 0;
    display: inline-block;
    padding: 0;
    position: relative;
    vertical-align: top;

    input {
      border-radius: 1px;
      font-size: 1rem;
      height: 40px;
      line-height: 28px;
      width: 100%;
      padding: 6px calc(12px + 4.32rem) 6px 12px;
      box-shadow: inset 0 0 2px 0 $nikon-grey;
      border: 0;
      &:focus {
        box-shadow: inset 0 0 2px 0 $nikon-grey !important;
        outline: 0 !important;
      }

      &::placeholder {
        color: #757575;
      }
    }

    .clearSearch {
      height: 38px;
      right: 38px;
      width: 38px;
      display: inline-block;
      background: 0 0;
      font-size: 0.8rem;
      line-height: 1rem;
      padding-top: 0.4rem;
      vertical-align: top;
      border: none;
      position: absolute;
      top: 1px;
    }

    .submitSearch {
      height: 38px;
      width: 38px;
      background: #f6f6f6;
      border-left: 1px solid $nikon-light-grey;
      right: 1px;
      position: absolute;
      top: 1px;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;

      img {
        vertical-align: middle;
        margin: auto;
      }
      &.active {
        background: #fd0;
      }
    }
  }
}

.search-results {
  &-container {
    background-color: $nikon-bg-color;
    border: 1px solid $nikon-search-result-border;
    margin: 0;
    min-height: 50px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 40px;
    width: 100%;
    z-index: 1001;
  }

  &-title {
    border: none;
    color: $nikon-dark-grey;
    font-family: $font-family-generic;
    font-size: rfs-value(13px);
    font-weight: 700;
    margin: 0;
    padding: 10px;
    text-align: left;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;
    border-top: 1px solid $nikon-search-result-border;

    &-item {
      border-top: 1px solid $nikon-search-result-border;

      &:hover,
      &:focus,
      &:focus-within {
        background-color: $nikon-search-result-item-hover-bg;
      }

      a {
        padding: rfs-value(20px 18px 20px 10px);
        text-decoration: none;
        display: flex;
        gap: rfs-value(20px);
        outline: 0;
      }

      &-img {
        min-width: rfs-value(32px);
        width: rfs-value(32px);
        height: auto;
        align-self: flex-start;
      }

      &-content {
        display: grid;
        gap: rfs-value(3px);
        text-align: left !important;
      }

      &-title,
      &-description {
        margin: 0;
        font-size: rfs-value(11px);
      }

      &-title {
        color: $nikon-link-bold;
        font-weight: $font-weight-bold;
        line-height: 1.3;
        letter-spacing: -0.11px;
      }

      &-description {
        color: $nikon-grey;
        font-weight: $font-weight-normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
        line-height: 1.35;
      }
    }
  }

  &-loader-container {
    display: flex;
    justify-content: center;
    padding: rfs-value(20px 10px);
    border-top: 1px solid $nikon-search-result-border;
  }
}

@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.showcase-product-hero {
  padding: 43px 7px 28px;
  position: relative;
  text-align: left;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 15px;

  .product-img-link {
    display: inline-block;
    margin: 0 15px;
    vertical-align: top;

    img {
      width: 100%;
    }
  }
  .content {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 30px;

    .main-text {
      display: block;
      position: relative;
      width: 100%;

      h3 {
        display: inline-block;
        font-size: rfs-value(25px);
        line-height: rfs-value(25px);
        font-weight: $font-weight-medium;
        color: $body-color;
        margin: 0;
        padding: 0;
        vertical-align: middle;

        @include media-breakpoint-down(md) {
          color: $nikon-link-bold;
          font-size: rfs-value(16px);

          &:hover {
            text-decoration: underline;
            text-underline-offset: 1px;
          }
        }
      }

      .description {
        display: block;
        font-size: rfs-value(13px);
        margin: rfs-value(8px 0 12px);

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .links {
        column-count: 2;
        column-gap: 60px;
        padding: 0;

        a {
          color: $nikon-link-bold;
          font-size: rfs-value(12px);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .extra-content {
      display: block !important;
      padding: 0;
      margin: 0;

      a {
        text-decoration: none;
        padding: 0;
      }

      .variants-available {
        display: block;
        font-size: rfs-value(12px);
        color: $nikon-dark-grey;
        font-weight: $font-weight-normal;
        margin-bottom: rfs-value(7px);
        line-height: rfs-value(20.4px);

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .price-container {
        .price {
          display: flex;
          flex-direction: column;
          margin-bottom: rfs-value(3px);

          &-text {
            font-size: rfs-value(14px);
            font-weight: $font-weight-bold;
            line-height: rfs-value(23.8px);
          }

          &-was {
            font-size: rfs-value(12px);
            line-height: rfs-value(20.4px);
          }
        }

        .you-save {
          font-size: rfs-value(12px);
          color: $nikon-blood-orange;
          line-height: rfs-value(20.4px);
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .rating-container {
        margin: rfs-value(17px 0);

        @include media-breakpoint-down(md) {
          margin-top: 0;
        }

        span {
          padding-left: 5px;
        }
      }

      .primary-cta {
        background-color: #fd0;
        border: 1px solid #cb0;
        border-radius: 2px;
        color: $nikon-dark-grey;
        display: inline-block;
        line-height: rfs-value(12px);
        padding: rfs-value(12px 40px);
        text-align: center;
        text-decoration: none;
        transition: background-color 0.2s ease;
        font-size: rfs-value(13px);
        font-weight: $font-weight-bold;
        width: auto;

        &:hover {
          background-color: #f0d000;
          color: $nikon-dark-grey;
          text-decoration: none;
        }

        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      gap: rfs-value(20px);
      padding-left: 15px;
    }
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr 2fr;
    margin: 0 rfs-value(15px);
    padding: rfs-value(30px 0);
    width: auto;
    gap: 0;
  }
}

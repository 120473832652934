@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.pagination {
  display: flex;
  gap: 5px;
  padding: 0;
  margin: 0;
  width: 100%;
  justify-content: center;

  @include media-breakpoint-up(md) {
    width: auto;
    gap: 10px;
    margin: 0;
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $nikon-black;
      text-decoration: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      font-size: rfs-value(14px);

      @include media-breakpoint-up(md) {
        width: 40px;
        height: 40px;
      }

      :global(.ss-navigateleft) {
        position: relative;
        top: 2px;
        left: -2px;
      }
      :global(.ss-navigateright) {
        position: relative;
        top: 2px;
        right: -2px;
      }
    }
    &:global(.active),
    &:focus,
    &:active {
      a {
        background: $body-contrast-color;
        font-weight: 700;
      }
    }

    &:global(.disabled) {
      &,
      &:focus,
      &:active,
      &:hover {
        a {
          pointer-events: none;
          opacity: 0.1;
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        a {
          background: $body-contrast-color;
          font-weight: 700;
        }
      }
    }
  }
}

.loading {
  margin-right: 10px;
  height: 40px;
  display: flex;
  align-items: center;
}

@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.container {
  display: grid;
  grid-template-columns: 20% 1fr;
  gap: 2rem;

  @include media-breakpoint-down(xxl) {
    grid-template-columns: 1fr 3fr;
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: 40% 1fr;
  }

  @include media-breakpoint-down(md) {
    grid-template-columns: none;
    grid-template-row: 1fr 1fr;
  }
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;
    gap: 1.25rem;
    padding: rfs-value(0 30px);
  }
}

.no-results-found-text {
  margin-top: rfs-value(150px);
  margin-bottom: rfs-value(10px);
  text-align: center;
  font-size: rfs-value(24px);
  color: $body-color;

  @include media-breakpoint-down(md) {
    margin-top: 0;
    padding-top: rfs-value(20px);
    border-top: 1px solid $nikon-light-grey;
  }
}

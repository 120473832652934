@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 2rem 0;

  & > div {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
  }
}

.icon {
  width: 14px;
  height: 14px;
  background-color: #000;
  -webkit-mask: url(/icons/chevron-down.svg) no-repeat center;
  mask: url(/icons/chevron-down.svg) no-repeat center;
}

.collapse-button {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 14px;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;
  cursor: pointer;
  background-color: $nikon-light-blue;
  padding: 1rem 1.8rem;
  font-weight: bold;
  border-radius: 1.5rem;
  font-size: rfs-value(20px);

  &::after {
    transition: all 0.2s ease-in-out;
  }

  &.down {
    &::after {
      content: '';
      @extend .icon;
    }
  }

  &.up {
    &::after {
      content: '';
      @extend .icon;
      transform: rotate(180deg);
    }
  }
}

.collapse-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.search-pagination-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

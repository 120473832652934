@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.dropdown {
  display: flex;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
    margin-bottom: 0;
  }

  :global(.dropdown-toggle) {
    letter-spacing: 0;

    &:global(.show),
    &:active,
    &:focus,
    &:hover {
      background: $body-contrast-color;
      color: $nikon-black;
    }
  }
  :global(.dropdown-item) {
    &:active,
    &:focus,
    &:hover {
      background: $body-contrast-color;
      color: $nikon-black;
    }
    &:global(.active) {
      background: none;
      color: $nikon-black;
      font-weight: 700;

      &:active,
      &:focus,
      &:hover {
        background: $body-contrast-color;
      }
    }
  }

  ul {
    color: $nikon-black;

    li {
      cursor: pointer;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    width: 100%;
    display: block;
    padding: rfs-value(4px) rfs-value(16px);
  }
}

@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';

.filter-item {
  &-title {
    color: $nikon-black;
    font-size: rfs-value(14px);
    margin: rfs-value(10px) 0;
  }

  &-list {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: rfs-value(5px);
  }

  &-option {
    display: flex;
    align-items: center;
    gap: rfs-value(8px);
    cursor: pointer;
    background-color: $nikon-white;
    border: 1px solid $nikon-light-grey;
    padding: rfs-value(7px 10px);
    font-weight: $font-weight-normal;
    color: $nikon-black;

    & > div {
      flex-grow: 1;
      cursor: pointer;
      margin-bottom: 0 !important;

      input:checked ~ label::after {
        width: 12px;
        height: 10px;
        left: 2px;
        top: 6px;
      }
    }

    &-checkbox {
      font-size: rfs-value(12px);
    }

    &:has(input:checked) {
      background-color: $nikon-grey;
      color: $nikon-white;
    }

    &:global(.disabled) {
      opacity: 0.65;
      cursor: default;
      pointer-events: none;
      box-shadow: none;
    }
  }
}

.help-link {
  color: #1d3994;
  text-decoration: underline;
  font-size: 1em;
  cursor: pointer;
}

.help-modal {
  padding: 20px;

  :global {
    .modal-header {
      padding: 0;
    }

    .modal-content {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    .btn-close {
      position: absolute;
      opacity: 1;
      width: 35px;
      height: 35px;
      padding: 0;
      margin: 0;
      top: 0;
      right: 0;
      border: 0;
      border-radius: 20px;
      box-shadow: 0 0 2px $nikon-grey;
      background: $nikon-white;
      color: $nikon-grey;
      line-height: 42px;
      z-index: 11;
      font-family: $icon-font;
      transform: translate(50%, -50%);

      &:before {
        color: $nikon-dark-grey;
        content: '␡';
        font-size: 18px;
        line-height: 40px;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: rfs-value(18px);
    margin-bottom: rfs-value(20px);
    line-height: rfs-value(21px);
  }

  p,
  ol,
  ul,
  li {
    margin: 0;
    font-size: rfs-value(12px);
    line-height: rfs-value(20.4px);
  }

  ol {
    list-style: auto;
  }

  ul {
    list-style: outside;
  }
}
